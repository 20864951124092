<template>
  <div class="input-group autocomplete-container">
    <GmapAutocomplete
      @place_changed="setPlace"
      :options="autocompleteOptions"
      class="form-control"
      id="autocomplete"
      :value="getCurrentPlace"
      @input="value = $event.target.value"
    />
    <button
      class="btn btn-outline-secondary"
      type="button"
      id="button-addon2"
      @click.prevent="getLocation()"
    >
      <i class="bi bi-geo-alt-fill"></i>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { googleApiKey } from "../../http-common";
// import $ from "jquery";
export default {
  data() {
    return {
      autocompleteOptions: {
        componentRestrictions: {
          country: ["ph"],
          // types: ['geocode']
        },
        //  types: ['(regions)'],
      },
      autocomplete: null,
      currentPlace: null,

      location: {
        region: "",
        province: "",
        municipal: "",
      },
    };
  },

  methods: {
    setPlace(place) {
      const center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.reverseGeocoding(center);
      console.table(place.address_components); // location checker
      this.$store.dispatch("ADD_CENTER", center);
      this.$store.dispatch("ADD_CURRENT_PLACE", place.formatted_address);
    },

    async getLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // return center
        this.$store.dispatch("ADD_CENTER", center);
      });

      await setTimeout(() => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.getCenter.lat},${this.getCenter.lng}&key=${googleApiKey}`
          )
          .then((result) => {
            const res = result.data;
            this.$store.dispatch(
              "ADD_CURRENT_PLACE",
              res.results[0].formatted_address
            );
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },

    async getStreetAddressFrom() {
      try {
        var { data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.getCenter.lat},${this.getCenter.lng}&key=${googleApiKey}`
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          // this.autocomplete = data.results[0].formatted_address;
          this.$store.dispatch(
            "ADD_CURRENT_PLACE",
            data.results[0].formatted_address
          );
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    async reverseGeocoding(position) {
      await setTimeout(() => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${googleApiKey}`
          )
          .then((result) => {
            // console.log(result);
            this.submitted = false;
            const res = result.data;
            let parts = res.results[0].address_components;
            parts.forEach((part) => {
              if (part.types.includes("administrative_area_level_1")) {
                this.location.region = part.short_name;
              }

              if (part.types.includes("administrative_area_level_2")) {
                this.location.province = part.long_name;
              }

              if (part.types.includes("locality")) {
                this.location.municipal = part.long_name;
              }
            });

            this.$emit("fomattedLocation", this.location);
          })
          .catch((err) => {
            console.log(err);
            return false;
          });
      }, 1000);
    },
  },

  computed: {
    getCenter() {
      return this.$store.getters.GET_CENTER;
    },

    getCurrentPlace() {
      return this.$store.getters.GET_CENTER_PLACE;
    },
  },

  mounted() {
    // $("#autocomplete")
    //   .parent()
    //   .css({ position: "relative" })
    //   .append(".pac-container");
  },
};
</script>

<style>
.pac-container {
  z-index: 9999 !important;
  /* top: 0 !important; */
}
</style>
