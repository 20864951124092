<template>
  <div
    class="modal fade modal-data-pack"
    ref="modalNotice"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header pb-1">
          <h5 class="text-blue">Reminder</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <p>Only the following areas are serviceable.</p>
              <div
                class="accordion accordion-flush"
                id="accordionAvailableAreas"
              >
                <div
                  class="accordion-item"
                  v-for="(area, a) in availableAreas"
                  :key="a"
                >
                  <div class="accordion-header" :id="`accordion-heading-${a}`">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      @click="toggle(a)"
                      aria-expanded="false"
                      :aria-controls="`accordion-idx${a}`"
                    >
                      {{ `${area.province}, ${area.region}` }}
                    </button>
                  </div>
                  <div
                    :id="`accordion-idx${a}`"
                    class="accordion-collapse collapse"
                    :aria-labelledby="`#accordion-heading-${a}`"
                    data-bs-parent="#accordionAvailableAreas"
                  >
                    <div class="accordion-body">
                      <ul>
                        <li
                          v-for="(municipal, i) in area.municipalities"
                          :key="`${a}_${i}`"
                        >
                          <span>
                            {{
                              municipal == "All" ? "All" : `${municipal} `
                            }}</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p class="mt-3">
                Please don't proceed with the application if your area is not
                included on the list.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-s2s-blue"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal, Collapse } from "bootstrap";
export default {
  data() {
    return {
      availableAreas: [
        // {
        //   municipalities: ["All"],
        //   province: "Metro Manila",
        //   region: "NCR",
        // },
        // {
        //   municipalities: ["Meycauayan", "Marilao", "Bocaue", "Santa Maria"],
        //   province: "Bulacan",
        //   region: "Central Luzon",
        // },
        // {
        //   municipalities: [
        //     "Tayabas",
        //     "Lucena",
        //     "Lucban",
        //     "Sariaya",
        //     "Candelaria",
        //     "Pagbilao",
        //   ],
        //   province: "Quezon",
        //   region: "Calabarzon",
        // },
        // {
        //   municipalities: ["Naga"],
        //   province: "Camarines Sur",
        //   region: "Bicol",
        // },
      ],
      background: "bg-f30",
      modal: null,
      resolve: null,
      reject: null,

      modals: {
        mobileNumber: null,
      },

      skus: [],
      selectedSku: {},

      mobileNumber: "",
      submitted: false,

      accordion: null,
    };
  },
  methods: {
    selectSku(item) {
      this.$store
        .dispatch("ADD_DATAPACK", item)
        .then(() => {
          this.modal.hide();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    open() {
      // const sku = this.$store.getters.GET_DATAPACK;

      // if (Object.keys(sku).length === 0) {
      //   // this.getLocation();
      //   // console.log("Asdasda");
      //   this.modal.show();
      // }

      // if (force) {
      //   this.modal.show();
      // }
      this.modal.show();

      // this.title = title;
      // this.message = message;
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      // this.dialog = false;
      this.modal.hide();
    },
    cancel() {
      this.resolve(false);
      // this.dialog = false;
      this.modal.hide();
    },

    async getAvailableAreas() {
      await this.$api
        .get("/online-application-config")
        .then((response) => {
          const res = response.data;

          let areas = res.data.availableAreas;
          areas.sort((a, b) => a.province.localeCompare(b.province));
          areas.sort((a, b) => a.region.localeCompare(b.region));

          areas.map((obj) => {
            return obj.municipalities.sort();
          });

          this.availableAreas = areas;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    toggle(i) {
      var myCollapse = document.getElementsByClassName("collapse")[i];
      new Collapse(myCollapse, {
        toggle: true,
      });
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modalNotice, {
      keyboard: false,
    });
  },

  computed: {
    isActive() {
      const sku = this.$store.getters.GET_DATAPACK;
      var def = { skuId: 0 };
      if (Object.keys(sku).length != 0) {
        return sku;
      }

      return def;
    },
  },

  created() {
    this.getAvailableAreas();
  },
};
</script>

<style scoped>
.accordion-button {
  padding: 10px 10px;
}
</style>
