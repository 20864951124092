<template>
  <div
    class="modal fade"
    ref="modalMaps"
    tabindex="-1"
    aria-labelledby="modalMapsLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h4 class="text-center fw-bolder text-navy-blue mt-3 mb-5">
                What's your exact location?
              </h4>
              <!-- <button @click="addMarker">Add</button> -->
              <div class="mb-4">
                <SearchLocation />
              </div>

              <GmapMap
                :center="getCenter"
                :zoom="15"
                style="width: 100%; height: 400px"
              >
                <GmapMarker
                  :position="getCenter"
                  :draggable="true"
                  @dragend="updateCoordinates"
                />
              </GmapMap>
              <!-- <gmap-marker
          v-for="(item, key) in coordinates"
          :key="key"
          :position="getPosition(item)"
          :clickable="true"
          :icon="getMarkers(key)"
          @click="toggleInfo(item, key)"
        ></gmap-marker> -->
            </div>
          </div>
        </div>
        <div class="modal-footer mr-auto">
          <!-- <div class="text-center"> -->
          <button
            type="button"
            class="btn btn-s2s-blue px-5"
            data-bs-dismiss="modal"
          >
            Ok
          </button>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import SearchLocation from "./SearchLocation.vue";
import { googleApiKey } from "../../http-common";
import axios from "axios";
export default {
  components: {
    SearchLocation,
  },
  data() {
    return {
      modals: {
        modalMaps: null,
      },
      // center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,
      markers: [],
      places: [],
      submitted: false,
    };
  },
  methods: {
    appendMobileNumber() {
      this.submitted = true;
      this.$validator.validate().then((valid) => {
        console.log(valid);
        if (valid) {
          this.submitted = false;
          window.location = `/?mobileNumber=${this.mobileNumber
            .replace(/\s+/g, "")
            .replace("+", "")}`;
        }
      });
    },

    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },

    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },

    async updateCoordinates(location) {
      const center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };

      this.$store.dispatch("ADD_CENTER", center);

      try {
        var { data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=${googleApiKey}`
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          // this.autocomplete = data.results[0].formatted_address;
          this.$store.dispatch(
            "ADD_CURRENT_PLACE",
            data.results[0].formatted_address
          );
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    close() {
      this.$emit("close", false);
    },
  },
  props: ["show"],

  watch: {
    show(e) {
      if (e) {
        this.modals.modalMaps.show();
      } else {
        this.modals.modalMaps.hide();
      }
    },
  },

  mounted() {
    this.modals.modalMaps = new Modal(this.$refs.modalMaps, {
      // keyboard: false,
    });

    var modal = this.$refs.modalMaps;
    const self = this;
    modal.addEventListener("hidden.bs.modal", function () {
      self.$emit("close", false);
    });

    // this.geolocate();
  },

  computed: {
    getCenter() {
      return this.$store.getters.GET_CENTER;
    },
  },
};
</script>

<style scoped>
.modal-content {
  min-height: 550px;
}

.pac-container {
  z-index: 9999 !important;
}
</style>
