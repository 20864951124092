<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-8">
        <div class="card card-custom shadow">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-6">
                <div class="left-side-bg"></div>
              </div>
              <div class="col-lg-6 my-5">
                <div class="mx-4">
                  <h4 class="text-center fw-bolder text-navy-blue">
                    Prepaid Fiber Online Application
                  </h4>
                  <div class="text-center mt-3">
                    <strong
                      >Find out if your community already has Surf2Sawa</strong
                    >
                  </div>

                  <div class="my-5">
                    <SearchLocation @fomattedLocation="getFomattedLocation" />

                    <small class="float-end">
                      <a href="" @click.prevent="showMaps()">Choose from Map</a>
                    </small>

                    <!-- <div class="mb-3 position-relative">
                      
                      <small class="float-end">
                        <a href="" @click.prevent="applyNow()">Choose from Map</a>
                      </small>
                    </div> -->
                    <!-- <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Select Region"
                      />
                    </div> -->

                    <div class="mt-3 pt-5">
                      <button
                        :disabled="submitted"
                        class="btn w-100 btn-s2s-blue"
                        @click.prevent="reverseGeocoding()"
                      >
                        <span
                          v-if="submitted"
                          class="spinner-border spinner-border-sm pl-5"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </span>
                        Apply now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-maps
      :show="modalMaps.isShow"
      @close="modalMaps.isShow = !modalMaps.isShow"
    ></modal-maps>

    <NoticeModal ref="noticeModal" />
    <Toast
      ref="toast"
      bgClass="bg-danger"
      position="bottom-0 start-50 translate-middle-x"
    />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ModalMaps from "../components/ModalMaps.vue";
import SearchLocation from "../components/SearchLocation.vue";
import { googleApiKey } from "../../http-common";
import axios from "axios";
import NoticeModal from "../components/NoticeModal.vue";
import Toast from "../components/Toast.vue";

export default {
  components: {
    ModalMaps,
    SearchLocation,
    NoticeModal,
    Toast,
  },

  data() {
    return {
      modalMaps: {
        isShow: false,
      },
      address: null,

      currentPlace: null,
      submitted: false,

      availableAreas: [
        // {
        //   municipalities: ["All"],
        //   province: "Metro Manila",
        //   region: "NCR",
        // },
        // {
        //   municipalities: ["Meycauayan", "Marilao", "Bocaue", "Santa Maria"],
        //   province: "Bulacan",
        //   region: "Central Luzon",
        // },
        // {
        //   municipalities: [
        //     "Tayabas",
        //     "Lucena",
        //     "Lucban",
        //     "Sariaya",
        //     "Candelaria",
        //     "Pagbilao",
        //   ],
        //   province: "Quezon",
        //   region: "Calabarzon",
        // },
        // {
        //   municipalities: ["General Trias"],
        //   province: "Cavite",
        //   region: "Calabarzon",
        // },
        // {
        //   municipalities: ["Naga"],
        //   province: "Camarines Sur",
        //   region: "Bicol",
        // },
      ],

      location: {
        region: "",
        province: "",
        municipal: "",
      },

      notAllowedAreas: [
        {
          province: "Ilocos Norte",
          cities: ["Banna"], // allowed cities
        },
        {
          province: "Leyte",
          cities: [], // blank means all city not allowed
        },
        {
          province: "Isabela",
          cities: ["Tumauini"],
        },
        {
          province: "Palawan",
          cities: [],
        },
        {
          province: "Abra",
          cities: [],
        },
        {
          province: "Agusan del Sur",
          cities: [],
        },
        {
          province: "Aklan",
          cities: [],
        },
        {
          province: "Bohol",
          cities: [],
        },
        {
          province: "Bukidnon",
          cities: [],
        },
        {
          province: "Cagayan",
          cities: [],
        },
        {
          province: "Camarines Norte",
          cities: ["Daet"],
        },
        {
          province: "Camarines Sur",
          cities: ["Nabua", "Buhi"],
        },
        {
          province: "Albay",
          cities: ["Legazpi City", "Tabaco City"],
        },
        {
          province: "Capiz",
          cities: [],
        },
        {
          province: "Davao del Norte",
          cities: [],
        },
        {
          province: "Ifugao",
          cities: [],
        },
        {
          province: "Ilocos Sur",
          cities: [],
        },
        {
          province: "Iloilo",
          cities: ["Iloilo City"],
        },
        {
          province: "Kalinga",
          cities: [],
        },
        {
          province: "Masbate",
          cities: ["Masbate City"],
        },
        {
          province: "Negros Occidental",
          cities: ["Cadiz City"],
        },
        {
          province: "Negros Oriental",
          cities: [],
        },
        {
          province: "Nueva Ecija",
          cities: ["Bongabon", "San Jose City", "Talavera", "Cabiao"],
        },
        {
          province: "Nueva Vizcaya",
          cities: ["Bayombong"],
        },
        {
          province: "Palawan",
          cities: [],
        },
        {
          province: "Quirino",
          cities: [],
        },
        {
          province: "South Cotabato",
          cities: [],
        },
        {
          province: "Zambales",
          cities: [],
        },
        {
          province: "Tarlac",
          cities: ["Tarlac City", "Tariji"],
        },
        {
          province: "Pampanga",
          cities: [
            "Mabalacat",
            "Floridablanca",
            "Cacutud",
            "San Fernando",
            "Arayat",
          ],
        },
        {
          province: "Aklan",
          cities: ["Kalibo"],
        },

        {
          province: "Quezon",
          cities: [
            "Atimonan",
            "Plaridel",
            "Gumaca",
            "Lopez",
            "Calauag",
            "Tagkawayan",
            "Guinayangan",
            "Buenavista",
            "Lucena",
            "Sariaya",
            "Pagbilao",
            "General Luna",
            "Mauban",
            "Candelaria",
            "Sampaloc",
            "Tiaong",
            "Dolores",
            "Lucban",
            "San Antonio",
            "Pitogo",
          ],
        },

        {
          province: "Davao del Sur",
          cities: ["Davao City"],
        },

        {
          province: "Laguna",
          cities: [
            "San Pablo City",
            "Alaminos",
            "Calauan",
            "Victoria",
            "Cabuyao",
            "Santa Maria",
            "Nagcarlan",
            "Liliw",
            "Majayjay",
            "Rizal",
            "Luisiana",
            "Los Baños",
            "Biñan",
          ],
        },
      ],
    };
  },

  methods: {
    async applyNow(payload) {
      // console.log(this.getCenter);
      // this.getLocation();
      this.submitted = true;

      // const payload = {
      //   lat: this.getCenter.lat,
      //   long: this.getCenter.lng,
      // };

      // this.reverseGeocoding(payload);

      await this.$api
        .post("/acquisition/location", payload)
        .then((response) => {
          this.submitted = false;
          const res = response.data;
          if (!res.data.available) {
            this.$store.dispatch("ADD_NOT_SERVICEABLE", true).then(() => {
              this.$router.push({ path: "not-serviceable" });
            });

            return;
          }

          // this.$store.dispatch("ADD_PREDATA", res.data);
          // this.$store.dispatch("ADD_APPLICATION_FORM", true).then(() => {
          //   // this.portReserve(res.data.referenceNumber)
          //   // this.$router.push({ path: "application-form" });
          //   this.$router.push({ path: "payment-selection" });
          // });

          // this.$store.dispatch("ADD_PREDATA", res.data);
          this.$store.dispatch("ADD_PREDATA", res.data).then(() => {
            this.$router.push({ path: "payment-selection" });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    showMaps() {
      this.modalMaps.isShow = true;
    },

    reFormatAccountNumber(num) {
      return num.replace(/\s+/g, "").replace("+", "");
    },

    clearLocation() {
      // this.location.region = "";
      // this.location.province = "";
      // this.location.municipal = "";
      this.location = {
        region: "",
        province: "",
        municipal: "",
      };
    },

    // old reverseGeocoding
    // async reverseGeocoding() {
    //   this.location = this.submitted = true;
    //   this.clearLocation();
    //   const position = {
    //     lat: this.getCenter.lat,
    //     long: this.getCenter.lng,
    //   };

    //   await setTimeout(() => {
    //     axios
    //       .get(
    //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.long}&key=${googleApiKey}`
    //       )
    //       .then((result) => {
    //         // console.log(result);
    //         this.submitted = false;
    //         const res = result.data;
    //         let parts = res.results[0].address_components;
    //         parts.forEach((part) => {
    //           if (part.types.includes("administrative_area_level_1")) {
    //             this.location.region = part.short_name;
    //           }

    //           if (part.types.includes("administrative_area_level_2")) {
    //             this.location.province = part.long_name;
    //           }

    //           if (part.types.includes("locality")) {
    //             this.location.municipal = part.long_name;
    //           }
    //         });

    //         var filterRegion = this.availableAreas.filter((item) => {
    //           return item.region === this.location.region;
    //         });
    //         // console.log(filterRegion);

    //         // exists in selected area
    //         if (filterRegion.length > 0) {
    //           var filterProvince = filterRegion.filter((item) => {
    //             return item.province === this.location.province;
    //           });

    //           if (filterProvince.length > 0) {
    //             // check if has a province
    //             if (filterProvince[0].municipalities.includes("All")) {
    //               // alert("allowed all");
    //               this.applyNow(position);
    //               return;
    //             } else {
    //               if (
    //                 filterProvince[0].municipalities.includes(
    //                   this.location.municipal
    //                 )
    //               ) {
    //                 // alert("many municipal allow");
    //                 this.applyNow(position);
    //                 return;
    //               }
    //             }
    //           } else {
    //             if (filterRegion[0].region === "NCR") {
    //               // special for NCR
    //               if (filterRegion[0].municipalities.includes("All")) {
    //                 // alert("allowed ncr");
    //                 this.applyNow(position);
    //                 return;
    //               } else {
    //                 if (
    //                   filterRegion[0].municipalities.includes(
    //                     this.location.municipal
    //                   )
    //                 ) {
    //                   // alert("multiple only ncr");
    //                   this.applyNow(position);
    //                   return;
    //                 }
    //               }
    //             }
    //           }
    //         }

    //         this.$swal(
    //           "Sorry",
    //           "Your area is not yet serviceable. Your application will not be processed.",
    //           "error"
    //         );
    //         return;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         return false;
    //       });
    //   }, 1000);
    // },

    async reverseGeocoding() {
      if (this.GET_CENTER_PLACE === "" || this.GET_CENTER_PLACE === null) {
        this.$refs.toast.open({ message: "Please select your location." });
        return;
      }

      this.location = this.submitted = true;
      this.clearLocation();
      const position = {
        lat: this.getCenter.lat,
        long: this.getCenter.lng,
      };

      await setTimeout(() => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.long}&key=${googleApiKey}`
          )
          .then((result) => {
            // console.log(result);
            this.submitted = false;
            const res = result.data;
            let parts = res.results[0].address_components;
            parts.forEach((part) => {
              if (part.types.includes("administrative_area_level_1")) {
                this.location.region = part.short_name;
              }

              if (part.types.includes("administrative_area_level_2")) {
                this.location.province = part.long_name;
              }

              if (part.types.includes("locality")) {
                this.location.municipal = part.long_name;
              }
            });

            var filterProvince = this.notAllowedAreas.filter((item) => {
              return item.province === this.location.province;
            });

            console.log(filterProvince);

            if (filterProvince.length) {
              const filterCity = filterProvince.filter((item) => {
                return item.cities.includes(this.location.municipal);
              });

              if (filterCity.length) {
                this.applyNow(position);
                return;
              }

              this.$swal(
                "Sorry",
                "Your area is not yet serviceable. Your application will not be processed.",
                "error"
              );

              return;
            }
            this.applyNow(position);
            // console.log("submit");
          })
          .catch((err) => {
            // console.log(err);
            this.$refs.toast.open({ message: err });
            return false;
          });
      }, 1000);

      //
    },

    async getLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // return center
        this.$store.dispatch("ADD_CENTER", center);
      });

      await setTimeout(() => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.getCenter.lat},${this.getCenter.lng}&key=${googleApiKey}`
          )
          .then((result) => {
            // console.log(result);
            const res = result.data;

            let parts = res.results[0].address_components;
            console.log(parts);
            parts.forEach((part) => {
              if (part.types.includes("administrative_area_level_1")) {
                console.log("region", part.short_name);
              }
            });
            this.$store.dispatch(
              "ADD_CURRENT_PLACE",
              res.results[0].formatted_address
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },

    async showNotice() {
      await this.$refs.noticeModal.open();
    },

    async getAvailableAreas() {
      await this.$api
        .get("/online-application-config")
        .then((response) => {
          const res = response.data;
          this.availableAreas = res.data.availableAreas;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getFomattedLocation({ data, done }) {
      if (done) {
        // this.location = data;
        console.log(data);
      }
    },

    //   onBackToParent({ data, done }) {
    //   // process data
    //   if (done) {
    //     done()
    //   }
    // }
  },

  created() {
    // this.showLocationModal();
    this.getAvailableAreas();
    if (Object.keys(this.getCenter).length === 0) {
      this.getLocation();
    }

    this.$store.dispatch("ADD_APPLICATIONFORMDATA", {});
    this.$store.dispatch("ADD_PREDATA", {});
    this.$store.dispatch("ADD_REFNUMBER", "");
    this.$store.dispatch("ADD_OTP_VERIFIED", false);

    const query = this.$route.query;

    if (Object.keys(query).length !== 0) {
      localStorage.setItem("code", query.code);
      this.$store.dispatch("ADD_AFFILIATECODE", query.code);
    }

    const code = localStorage.getItem("code");
    this.$store.dispatch("ADD_AFFILIATECODE", code);

    // if (Object.keys(query).length === 0) {
    //   this.$store.dispatch("ADD_AFFILIATECODE", "");
    // } else {
    //   this.$store.dispatch("ADD_AFFILIATECODE", query.code);
    // }
    // const accountNum = localStorage.getItem("accNum");
    // if (accountNum) {
    //   this.accountNumber = accountNum;
    // }
  },

  validations: {
    accountNumber: {
      required,
    },
  },

  mounted() {
    // this.showNotice();
  },

  computed: {
    getCenter() {
      return this.$store.getters.GET_CENTER;
    },

    GET_CENTER_PLACE() {
      return this.$store.getters.GET_CENTER_PLACE;
    },
  },
};
</script>
